.hero-container {
    background-image: url(/public//assets/images/12.png);
    height: 100vh;
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    background-size: contain;
    /* opacity: 0.7; */
    background-color: #000000;
    /* opacity: 0.75; */
    z-index: 10;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.background-overlay {
    background-color: #000000;
    opacity: 0.75;
    /* z-index: -1; */
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

}

.title {
    font-family: 'Rubik', sans-serif;
    z-index: 10;
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 75px;
    font-weight: 700;
    letter-spacing: 2px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #89E8FF;
    stroke: #89E8FF;
    text-shadow: 0px 0px 10px rgba(137.00000000000003, 232.00000000000003, 255, 0.73);
}


.hero-register-button {
    /* z-index: 20; */
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFF00 0%, #51C2FF36 100%);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #C6F4FF;
    box-shadow: 0px 0px 10px 0px rgba(163.53260869565216, 237.17161016949154, 255, 0.79);
}

.hero-register-button:hover {
    /* ease-in-out animation */
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transform: scale(0.95);
    /* IE 9 */
    -webkit-transform: scale(0.95);
    /* Safari 3-8 */
    transform: scale(0.95);
}



.section-about {
    height: auto;
    background-attachment: fixed;
    background-image: url(https://i0.wp.com/jili-ko.ph/wp-content/uploads/2023/09/新增副標題.jpg?fit=1920%2C1080&ssl=1);
    z-index: 20;

}

.section-background-overlay {
    background-color: #000000;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;

}

.about-right {
    z-index: 20;
    margin: -104px 0px 0px 0px;
}

.title-hero {
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 2px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #89E8FF;
    stroke: #89E8FF;
    text-shadow: 0px 0px 10px rgba(137.00000000000003, 232.00000000000003, 255, 0.73);

}

.top-games {
    background-color: #78A2FF47;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #6A89FF;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 5px 5px 5px 5px px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
}

.top-games-title {
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 2px;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: #89E8FF;
    stroke: #89E8FF;
    text-shadow: 0px 0px 10px rgba(137.00000000000003, 232.00000000000003, 255, 0.73);

}

.features-games {
    background-color: #FF787847;
    margin: 0;
    height: 100%;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #F85757;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 10px 0px 10px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
}

.top:hover .images {
    transition: all 0.4s ease-in-out;
    opacity: .3;
}

footer .img-footer {}