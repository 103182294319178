.bg-header {
    z-index: 20;
    background-color: transparent;
    background-image: linear-gradient(0deg, #000000 0%, #0D3850 100%);
}

.div-links.active {
    background-color: #1073BE;
}


/*===================*/
/* PULSE GROW
/*===================*/

@-webkit-keyframes pulse-grow-on-hover {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes pulse-grow-on-hover {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.pulse-register {
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    fill: #EAF7FF;
    color: #EAF7FF;
    background-color: #1073BE;
    border-style: none;
    border-radius: 6px 6px 6px 6px;
}

.pulse-more {
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFF00 0%, #D7F7FF73 100%);
    border-style: solid;
    border-color: #D7F7FF;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.pulse-grow-on-hover {
    /* display: inline-block; */

    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    /* z-index: 0; */
}

.pulse-grow-on-hover:hover,
.pulse-grow-on-hover:focus,
.pulse-grow-on-hover:active {
    -webkit-animation-name: pulse-grow-on-hover;
    animation-name: pulse-grow-on-hover;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}