@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&family=Rubik:wght@400;500&family=VT323&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* background-color: blue; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

img{
  height: auto !important;
  width: 100% !important; 
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */